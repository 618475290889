/* ==========================================================================

	Kallyas JS scripts.
	Contains scripts dedicated for this template.

	Do not change nothing as it will be constantly updated and your work might
	get overwritten.

========================================================================== */

//#! Enable with "1" or disable with "0" which functionality you want to use.
var KallyasConfig = {
	// Set your own domain to enable the Google search functionality on your website.
	searchDomain: 'https://kallyas-template.net',

	// Enable Chaser Menu (Chaser menu or Sticky Header, not both on the same time.)
	enableChaserMenu: 0,

	// The distance in pixels from the top of the page when to display the chaser menu
	chaserMenuOffsetDisplay: 150,

	// Enable Sticky Header
	enableStickyHeader: 1,

	// Enable Header ScrollSpy (Especially for One Page layouts that has OnePage menu (Landing Pages).)
	enableScrollSpy: 0,

	// Display the back to top button
	enableBackToTop: 1
};
//!#----------



/*
* Closure for Page Load
*/
(function($, window, document) {
	"use strict";

// Define global vars
	var $window = $(window),
		$html = $("html"),
		$body = $("body"),
		$header = $("#header");


// Notice to prevent opening on a local environment
	$(document).ready(function () {
		if(window.location.href.indexOf("file:///") > -1) {
			alert("Local environment warning!! \n\nSome of the features of this template might not work properly if the .html file is opened locally. \n\nFor best results, please preview on a web server (either http://yourdomain.com/kallyas/ or from your computer with an emulated server eg: http://localhost/kallyas/ ). Please read the documentation to learn more. ");
		}
	});
// END Notice to prevent opening on a local environment


// FIX mask for Safari Browser
	if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
		document.getElementsByTagName('body')[0].className+=' is-safari';
	}
// END FIX mask for Safari Browser


// Search panel
	var searchBtn = $('#search').children('.searchBtn'),
		searchPanel = searchBtn.next(),
		searchP = searchBtn.parent();
		searchBtn.click(function(e){
		e.preventDefault();
		var _t = $(this);
		if(!_t.hasClass('active')) {
			_t.addClass('active')
			.find('span')
			.removeClass('fa-search white-icon')
			.addClass('fa-times');
			searchPanel.show();
		} else {
			_t.removeClass('active')
			.find('span')
			.addClass('fa-search white-icon')
			.removeClass('fa-times');
			searchPanel.hide();
		}
	}); // searchBtn.click //

	$(document).click(function(){
		searchBtn.removeClass('active')
			.find('span')
			.addClass('fa-search white-icon')
			.removeClass('fa-times');
		searchPanel.hide(0);
	});

	searchP.click(function(event){
		event.stopPropagation();
	});
// END Search panel


// Scroll to top
	if(KallyasConfig.enableBackToTop){
		if ($('#totop').length) {
			var scrollTrigger = 200, // px
			backToTop = function () {
				var scrollTop = $(window).scrollTop();
				if (scrollTop > scrollTrigger) {
					$('#totop').addClass('show');
				} else {
					$('#totop').removeClass('show');
				}
			};
			backToTop();
			$(window).on('scroll', function () {
				backToTop();
			});
			$('#totop').on('click', function (e) {
				e.preventDefault();
				$('html,body').animate({
					scrollTop: 0
				}, 700);
			});
		}
	}
// END Scroll to top


// Magnific Popup
	if(typeof($.fn.magnificPopup) != 'undefined')
	{
		$('a.kl-login-box').magnificPopup({
			type: 'inline',
			closeBtnInside:true,
			showCloseBtn: true,
			mainClass: 'mfp-fade mfp-bg-lighter'
		});

		$('a[data-lightbox="image"]:not([data-type="video"])').each(function(i,el){
			//single image popup
			if ($(el).parents('.gallery').length == 0) {
				$(el).magnificPopup({
					type:'image',
					tLoading: '',
					mainClass: 'mfp-fade'
				});
			}
		});
		$('.mfp-gallery.images').each(function(i,el) {
			$(el).magnificPopup({
				delegate: 'a',
				type: 'image',
				gallery: {enabled:true},
				tLoading: '',
				mainClass: 'mfp-fade'
			});
		});

		// Notice the .misc class, this is a gallery which contains a variatey of sources
		// links in gallery need data-mfp attributes eg: data-mfp="image"
		$('.mfp-gallery.misc').each(function(){
			$(this).magnificPopup({
				delegate: 'a[data-lightbox="mfp"]',
				mainClass: 'mfp-fade',
				type: 'image',
				gallery: {enabled:true},
				tLoading: '',
				callbacks: {
					elementParse: function(item) {
						item.type = $(item.el).attr('data-mfp');
					}
				}
			});
		});
		

		$('a[data-lightbox="iframe"]').magnificPopup({type: 'iframe', mainClass: 'mfp-fade', tLoading: ''});
		$('a[data-lightbox="inline"]').magnificPopup({type: 'inline', mainClass: 'mfp-fade', tLoading: ''});
		$('a[data-lightbox="ajax"]').magnificPopup({type: 'ajax', mainClass: 'mfp-fade', tLoading: ''});
		$('a[data-lightbox="youtube"], a[data-lightbox="vimeo"], a[data-lightbox="gmaps"], a[data-type="video"]').magnificPopup({
			disableOn: 700,
			type: 'iframe',
			removalDelay: 160,
			preloader: true,
			fixedContentPos: false,
			mainClass: 'mfp-fade',
			tLoading: ''
		});

		// Enable WooCommerce lightbox
		$('.single_product_main_image .images a').magnificPopup({
			mainClass: 'mfp-fade',
			type: 'image',
			gallery: {enabled:true},
			tLoading: '',
		});
	}
// END Magnific Popup


// Flickr Feed
	var content = $('.flickrfeed');
	if( content ){
		var elements = content.find('.flickr_feeds');
		if(elements && elements.length){
			$.each(elements, function(i, e){
				var self = $(e),
					ff_limit = (self.attr('data-limit') ? self.attr('data-limit') : 6),
					fid = self.attr('data-fid');
				if(typeof($.fn.jflickrfeed) != 'undefined') {
					self.jflickrfeed({
						limit: ff_limit,
						qstrings: { id: fid },
						itemTemplate: '<li><a href="{{image_b}}" data-lightbox="image"><img src="{{image_s}}" alt="{{title}}" /><span class="theHoverBorder"></span></a></li>'
					},
					function(data) {
						self.find(" a[data-lightbox='image']").magnificPopup({type:'image', tLoading: ''});
						self.parent().removeClass('loadingz');
					});
				}
			});
		}
	}
// END Flickr Feed


// Map toggle panel
	elements = $('.kl-contentmaps__panel-tgg');
	if(elements){
		elements.each(function(a, b){
			var element = $(b);
			element.on('click', function(e){
				e.preventDefault();
				e.stopPropagation();
				var targetElement = $(element.data('target'));
				if(targetElement){
					var toggleClass = element.data('targetClass');
					if(toggleClass){
						targetElement.toggleClass(toggleClass);
					}
				}
			});
		});
	}
// END Map toggle panel


// Stage icon boxes element - .kl-ib-point-active => activates the dot (see :hover)
	elements = $('.kl-iconbox');
	if(elements){
		$.each(elements, function(a,b){
			var element = $(b),
				target = $(element.data('targetElement')); // data-target-element="kl-ib-point-1"
			if(target){
				element.on('mouseenter', function(e){
					target.addClass('kl-ib-point-active');
				}).on('mouseleave', function(){
					target.removeClass('kl-ib-point-active');
				});
			}
		});
	}
// END Stage icon boxes element



// Sub-header mask effect
	if (matchMedia('only screen and (min-width: 767px)').matches && $html.hasClass('js')){
		var scrollSvg = $('.kl-slideshow, #page_header');
		if( scrollSvg && scrollSvg.length > 0 )
		{
			var scrollSvgHeight = (parseFloat(scrollSvg.css('height')) + 20),
				svgEffect = scrollSvg.find('.screffect');
			$window.scroll(function(){
				var scrollPos = $window.scrollTop();
				if(scrollPos < scrollSvgHeight) {
					var maxSkew = 2;
					var effectPos = (maxSkew / scrollSvgHeight) * scrollPos;
					svgEffect.css({ '-webkit-transform': 'skewY(-'+ effectPos +'deg)', 'transform': 'skewY(-'+ effectPos +'deg)' });
				}
			});
		}
	}
// END Sub-header mask effect


// Popup Box
// Works with Magnific popup to open them
// data-ppbox-timeout attribute needed to specify the timeout to appear
	if(typeof($('.kl-pp-box[data-ppbox-timeout]')) != 'undefined') {
		$('.kl-pp-box[data-ppbox-timeout]').each(function(index, el) {
			var $el = $(el),
				pptimeout = $el.attr('data-ppbox-timeout'),
				timeout = (typeof pptimeout == 'undefined' && $(pptimeout).length <= 0) ? pptimeout : 8000;

			var cookieExpireAttr = $el.attr('data-cookie-expire'),
				cookieExpire = (typeof cookieExpireAttr !== 'undefined') ? cookieExpireAttr : 2;

			// Remove cookie if  you want to test
			// $.removeCookie('ppbox', { path: '/' });

			// check if cookie exists
			if(!$.cookie('ppbox')) {
				// show the popupbox
				var timer = setTimeout(function(){
					$.magnificPopup.open({
						items: {
							src: $($el.get(0))
						},
						type: 'inline',
						mainClass: 'mfp-fade mfp-bg-lighter',
						tLoading: ''
					});
				}, timeout);
			}
			// Set cookie and close popup
			$(el).find('.dontshow').on('click',function(e){
				e.preventDefault();
				// Add cookie support
				$.cookie('ppbox', 'hideit', { expires: parseInt(cookieExpire), path: '/' });
				//Close Popup
				$.magnificPopup.close();
			});
		});
	}
// END Popup Box


// Tooltips
// ** for more details how to use tooltips, you can check bootstrap documentation: http://getbootstrap.com/javascript/#tooltips
	if(typeof($.fn.tooltip) != 'undefined' && typeof($('[data-toggle="tooltip"]')) != 'undefined') {
		$('[data-toggle="tooltip"]').tooltip();
	}
// END Tooltips



// Process items: make the line behind the elements
	var selectors = $(".processitems li");
	if( selectors && selectors.length > 0)
	{
		$(".processitems li").each(function(i,el) {
			$(el).on({
			'mouseenter':function(){
				$(this).prevAll().each(function() {
					$(this).addClass("lined");
				});
			},
			'mouseleave':function(){
				$(this).siblings().each(function() {
					$(this).removeClass("lined");
				});
			}});
		});
	}
// end Process items: make the line behind the elements




})(window.jQuery, window, document);


// ScollSpy One page Menu 
if(KallyasConfig.enableScrollSpy){
			//#! [Configure ScrollSpy] Set the attribute on body
	$(window).on('load scroll resize', function(){
		var bodyScrollSpy = $('body[data-spy="scroll"]');
		if(KallyasConfig.enableChaserMenu && $(window).scrollTop() > KallyasConfig.chaserMenuOffsetDisplay ) {
			bodyScrollSpy.attr('data-target', '#wpk-main-menu');
		}
		else {
			bodyScrollSpy.attr('data-target', '#main-menu');
		}
	});

	jQuery(function($){
		var bodyScrollSpy = $('body')
			bodyScrollSpy
				.css('position', 'relative')
				.attr('data-spy', 'scroll')
				.attr('data-offset', '5');

		// OnLoad
		var hash = window.location.hash;
		if(hash){
			// check the main menu first
			var targets = $('#menu-main-menu > li > a');
			$.each(targets, function(a,b){
				if( hash == $(b).attr('href')){
					$(b).parents('ul').first().find('*').removeClass('active');
					$(b).addClass('active');
					$(hash).attr('tabindex', '-1');
					$(hash).focus();
				}
			});
		}
		// Menu item OnClick
		$('#menu-main-menu > li > a, .chaser li a').on('click',function(e) {
			var item = $(this);
			if(! item.hasClass('active')) {
				item.parents('ul').find('*').removeClass('active');
			    item.addClass('active');
			}
		});
	});
}
// end ScollSpy One page Menu 


//Parallax
(function ($) {
	$.KyHtml = function () {
			/* Setup vars */
			var doc = $(document),
					__touch = ((typeof Modernizr === 'object' && Modernizr.touchevents) || false),
					isMobile = (__touch && window.matchMedia("(max-width: 1024px)").matches);

			this.init = function () {
					this.parallaxInit(doc);
			};

			this.parallaxInit = function (context) {
					if (typeof($.fn.KyHtmlParallax) !== 'undefined' && !isMobile) {
							var $element = $(".js-KyHtmlParallax", context);
							if ($element && $element.length > 0) {
									$element.KyHtmlParallax();
							}
					}
			};
	};

	/*@dev: For later usage if needed */
	$(function () {
			$.KyHtmlInstance = new $.KyHtml();
			//#! Initialize
			$.KyHtmlInstance.init();
	});
})(jQuery);

